import { useEffect, useState } from 'react'

function useFormatDate({ date, time, weekday, day, month, year, hour, minute }) {
  const [formattedDate, setFormattedDate] = useState('')

  useEffect(() => {
    const options = {
      ...(weekday && { weekday }),
      ...(day && { day }),
      ...(month && { month }),
      ...(year && { year }),
      ...(hour && { hour }),
      ...(minute && { minute }),
    }

    let dateString = ''

    if (date) dateString = new Date(date).toLocaleDateString('nl-NL', options)
    if (time) dateString = new Date(time).toLocaleTimeString('nl-NL', options)

    if (weekday) {
      dateString = dateString.slice(0, 2) + '.' + dateString.slice(2)
    }

    if (month === 'short') {
      const isMonthMay = dateString.includes('mei')
      if (!isMonthMay) dateString += '.'
    }

    setFormattedDate(dateString)
  }, [date, time, weekday, day, month, year, hour, minute])

  return formattedDate
}

export default useFormatDate
