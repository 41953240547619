import styles from './AboutSection.module.scss'
import cn from 'classnames'
import Image from 'next/image'
import Badge from '@/components/Badge/Badge'
import Button from '@/components/Button/Button'
import WYSIWYG from '@/components/WYSIWYG/WYSIWYG'
import renderCustomIcon from '@/utils/renderCustomIcon'

function AboutSection({ data, className, ...props }) {
  const Lines = renderCustomIcon('lines')

  return (
    <section
      className={cn(styles['root'], { [className]: className })}
      {...props}
    >
      <div className={cn(styles['person'])}>
        <Lines />

        <div className={cn(styles['image'])}>
          <div className={cn(styles['image__main'])}>
            <Image
              src={data.teamMember.image.sourceUrl}
              alt={data.teamMember.image.altText}
              fill
            />
          </div>
        </div>

        <div className={cn(styles['card'])}>
          <p>{data.teamMember.name}</p>
          <p>{data.teamMember.function}</p>
        </div>
      </div>

      <div className={cn(styles['content'])}>
        <Badge>Over ons</Badge>

        <h2>{data.title}</h2>

        <WYSIWYG>{data.description}</WYSIWYG>

        {data.primaryButton && (
          <Button
            href={data.primaryButton.url}
            outline
            {...(data.primaryButton.target && { target: data.primaryButton.target })}
          >
            {data.primaryButton.title}
          </Button>
        )}
      </div>
    </section>
  )
}

export default AboutSection
