import styles from './NewsSection.module.scss'
import { StarIcon } from '@heroicons/react/24/solid'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import Badge from '@/components/Badge/Badge'
import Button from '@/components/Button/Button'
import useFormatDate from '@/hooks/useFormatDate'
import renderCustomIcon from '@/utils/renderCustomIcon'

function NewsSection({ data, className, ...props }) {
  const Lines = renderCustomIcon('lines')

  return (
    <section
      className={cn(styles['root'], { [className]: className })}
      {...props}
    >
      <Lines />

      <h2>Nieuws</h2>

      <div className={cn(styles['wrapper'])}>
        <ul className={cn(styles['highlight'])}>
          {data.highlightedNewsItems.map((item, index) => {
            return (
              <li
                data-hide-on-mobile={index > 0}
                key={item.uri}
              >
                <Card data={item} />
              </li>
            )
          })}
        </ul>

        <div className={cn(styles['latest'])}>
          <h3>Laatste nieuws</h3>

          <ul>
            {data.latestPosts.map((item, index) => {
              return (
                <li
                  data-hide-on-mobile={index > 2}
                  key={item.uri}
                >
                  <Link href={item.uri}>
                    <p>
                      <span>{item.title}</span>

                      {item?.membersAcf?.membersOnly && (
                        <Badge className={cn('badge--member', styles['badge'])}>
                          <StarIcon />
                          Leden
                        </Badge>
                      )}
                    </p>

                    <div>
                      <div>
                        <Image
                          src={item.featuredImage.node.sourceUrl}
                          alt={item.featuredImage.node.altText}
                          fill
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              )
            })}
          </ul>

          <Button
            href="/nieuws"
            outline
          >
            Meer nieuws
          </Button>
        </div>
      </div>
    </section>
  )
}

function Card({ data }) {
  const date = useFormatDate({ date: data.date, day: 'numeric', month: 'long', year: 'numeric' })

  return (
    <Link href={data.uri}>
      <div className={cn(styles['image'])}>
        <div>
          <Image
            src={data.featuredImage.node.sourceUrl}
            alt={data.featuredImage.node.altText}
            fill
          />
        </div>
      </div>

      <div className={cn(styles['content'])}>
        <p>
          <span>{data.title}</span>

          {data?.membersAcf?.membersOnly && (
            <Badge className={cn('badge--member')}>
              <StarIcon />
              Leden
            </Badge>
          )}
        </p>

        <span className={cn(styles['date'])}>{date}</span>

        {data.categories?.nodes.length > 0 && (
          <ul>
            {data.categories?.nodes.map((category) => {
              return (
                <li key={category.name}>
                  <Badge>{category.name}</Badge>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </Link>
  )
}

export default NewsSection
