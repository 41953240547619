import styles from './QuickLinks.module.scss'
import cn from 'classnames'
import Link from 'next/link'
import renderCustomIcon from '@/utils/renderCustomIcon'

function QuickLinks({ data, className, ...props }) {
  return (
    <section
      className={cn(styles['root'], { [className]: className })}
      {...props}
    >
      <h2>Ga snel naar:</h2>

      <ul>
        {data.map((link, index) => {
          const Icon = renderCustomIcon(link.icon)

          return (
            <li key={link.icon + index}>
              <Link href={link.button.url}>
                <div>
                  <Icon />
                </div>

                <p>{link.button.title}</p>
              </Link>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default QuickLinks
