import styles from './WYSIWYG.module.scss'
import cn from 'classnames'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

function WYSIWYG({ children, className, ...props }) {
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]}
      className={cn('wysiwyg', styles['root'], { [className]: className })}
      {...props}
    >
      {children}
    </ReactMarkdown>
  )
}

export default WYSIWYG
