import styles from './EventsAndPodcasts.module.scss'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import Button from '@/components/Button/Button'
import useFormatDate from '@/hooks/useFormatDate'
import renderCustomIcon from '@/utils/renderCustomIcon'

function EventsAndPodcasts({ data, className, ...props }) {
  return (
    <section
      className={cn(styles['root'], { [className]: className })}
      {...props}
    >
      <div className={cn(styles['wrapper'])}>
        <h2>Agenda</h2>

        <ul>
          {data.events.map((event) => {
            const eventData = {
              title: event.eventAcf.startDateTime,
              description: event.title,
              uri: event.uri,
              image: event.featuredImage?.node,
            }

            return (
              <li key={event.uri}>
                <Card
                  className={cn(styles['card'])}
                  data={eventData}
                  type="event"
                />
              </li>
            )
          })}
        </ul>

        <Button
          href="/agenda"
          outline
        >
          Agenda
        </Button>
      </div>

      <div className={cn(styles['wrapper'])}>
        <h2>Podcasts</h2>

        <ul>
          {data.podcasts.map((podcast) => {
            const podcastData = {
              title: podcast.title,
              description: podcast?.podcastAcf?.podcastGuest,
              uri: podcast.uri,
              image: podcast.featuredImage?.node,
            }

            return (
              <li key={podcast.uri}>
                <Card
                  className={cn(styles['card'])}
                  data={podcastData}
                  type="podcast"
                />
              </li>
            )
          })}
        </ul>

        <Button
          href="/podcasts"
          outline
        >
          Podcasts
        </Button>
      </div>
    </section>
  )
}

function Card({ data, type, className, ...props }) {
  const CalendarIcon = renderCustomIcon('calendar')
  const date = useFormatDate({ date: data.title, weekday: 'short', day: 'numeric', month: 'short' })

  return (
    <Link
      href={data.uri}
      className={cn({ [className]: className })}
      {...props}
    >
      <div className={cn(styles['media'])}>
        {data.image ? (
          <Image
            src={data.image.sourceUrl}
            alt={data.image.altText}
            fill
          />
        ) : (
          <CalendarIcon />
        )}
      </div>

      <div className={cn(styles['meta'])}>
        <p>{type === 'event' ? date : data.title}</p>
        <p>{data.description}</p>
      </div>
    </Link>
  )
}

export default EventsAndPodcasts
