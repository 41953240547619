import AboutSection from './partials/AboutSection'
import EventsAndPodcasts from './partials/EventsAndPodcasts'
import IntroHeader from './partials/IntroHeader'
import IntroSlider from './partials/IntroSlider'
import NewsSection from './partials/NewsSection'
import QuickLinks from './partials/QuickLinks'
import BecomeMember from '@/components/BecomeMember'
import AppLayout from '@/layouts/AppLayout'
import LayoutService from '@/services/LayoutService'
import PageService from '@/services/PageService'

function Page({ page, layoutData }) {
  const acf = page.homeAcf

  return (
    <AppLayout
      page={page}
      data={layoutData}
    >
      <main>
        <IntroHeader data={acf.introHeader} />

        <IntroSlider data={acf.introSlider} />

        <NewsSection data={acf.news} />

        <BecomeMember data={acf.becomeMember} />

        <QuickLinks data={acf.quickLinks} />

        <EventsAndPodcasts data={acf.eventsAndPodcasts} />

        <AboutSection data={acf.aboutUs} />
      </main>
    </AppLayout>
  )
}

export async function getStaticProps({ params, preview, previewData }) {
  let id = 'homepage'
  if (params) {
    id = params.id
  }

  const isId = Number.isInteger(Number(id))
  const idType = isId ? 'DATABASE_ID' : 'URI'

  try {
    const { data: pageData } = await PageService().home(idType, previewData)
    const { data: layoutData } = await LayoutService().index()
    let page = pageData.page

    page.homeAcf.news.latestPosts = pageData.posts.nodes
    page.homeAcf.eventsAndPodcasts = { events: pageData.events.nodes, podcasts: pageData.podcasts.nodes }

    if (preview) {
      page = { ...page, ...page.revisions.nodes[0] }
    }

    return {
      props: { page, layoutData },
    }
  } catch (error) {
    throw new Error(error)
  }
}

export default Page
