import styles from './BecomeMember.module.scss'
import cn from 'classnames'
import Image from 'next/image'
import Badge from '@/components/Badge/Badge'
import Button from '@/components/Button/Button'

function BecomeMember({ data, className, ...props }) {
  return (
    <section
      className={cn(styles['root'], { [className]: className })}
      {...props}
    >
      <div className={cn(styles['wrapper'])}>
        <div className={cn(styles['image'])}>
          <Image
            src={data.image.sourceUrl}
            alt={data.image.altText}
            width={data.image.mediaDetails.width}
            height={data.image.mediaDetails.height}
          />

          <Image
            src={data.backgroundImage.sourceUrl}
            alt={data.backgroundImage.altText}
            width={data.backgroundImage.mediaDetails.width}
            height={data.backgroundImage.mediaDetails.height}
          />
        </div>

        <div className={cn(styles['content'])}>
          <Badge>{data.label}</Badge>

          <h2>{data.title}</h2>

          <p>{data.description}</p>

          {(data.primaryButton || data.secondaryButton) && (
            <div>
              <div>
                {data.primaryButton && (
                  <Button
                    href={data.primaryButton.url}
                    color="white-gray"
                    outline
                    {...(data.primaryButton.target && { target: data.primaryButton.target })}
                  >
                    {data.primaryButton.title}
                  </Button>
                )}

                {data.secondaryButton && (
                  <Button
                    href={data.secondaryButton.url}
                    color="yellow"
                    {...(data.secondaryButton.target && { target: data.secondaryButton.target })}
                  >
                    {data.secondaryButton.title}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default BecomeMember
