import styles from './IntroHeader.module.scss'
import cn from 'classnames'
import Button from '@/components/Button/Button'

function IntroHeader({ data, className, ...props }) {
  return (
    <section
      className={cn(styles['root'], { [className]: className })}
      {...props}
    >
      <p>{data.title}</p>

      <div>
        <div className={styles['buttons']}>
          <Button
            href={data.primaryButton.url}
            outline
            {...(data.primaryButton.target && { target: data.primaryButton.target })}
          >
            {data.primaryButton.title}
          </Button>

          <Button
            href={data.secondaryButton.url}
            outline
            {...(data.secondaryButton.target && { target: data.secondaryButton.target })}
          >
            {data.secondaryButton.title}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default IntroHeader
