import styles from './IntroSlider.module.scss'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { StarIcon } from '@heroicons/react/24/solid'
import cn from 'classnames'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Badge from '@/components/Badge/Badge'

function IntroSlider({ data = [], className, ...props }) {
  const [currentSlide, setCurrentSlide] = useState(0)

  const lastTabIndex = data.length - 1

  function selectPreviousTab() {
    setCurrentSlide(currentSlide === 0 ? lastTabIndex : currentSlide - 1)
  }

  function selectNextTab() {
    setCurrentSlide(currentSlide === lastTabIndex ? 0 : currentSlide + 1)
  }

  if (data.length === 0) return null

  return (
    <div className="bg-beige-light">
      <section
        className={cn('md:container', styles['root'], { [className]: className })}
        {...props}
      >
        <Carousel
          selectedItem={currentSlide}
          autoPlay={true}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          interval={5000}
        >
          {data.map((entry, index) => {
            return (
              <Slide
                key={index}
                className={cn(styles['slide'])}
                data={entry}
              />
            )
          })}
        </Carousel>

        <Controls
          className={cn(styles['controls'])}
          selectPreviousTab={selectPreviousTab}
          selectNextTab={selectNextTab}
        />
      </section>
    </div>
  )
}

function Slide({ data, children, className, ...props }) {
  const mediaRef = useRef(null)

  const isVideo = data.imageOrVideo === 'video'
  const href = data.buttonOverwrite ? data.buttonOverwrite.url : data.connectedItem?.uri
  const ParentWrapper = isVideo ? 'div' : 'a'
  const ReadMoreWrapper = isVideo ? 'a' : 'span'

  useEffect(() => {
    function setHeightVariable() {
      const mediaHeight = mediaRef.current.offsetHeight
      document.querySelector('.control-dots').style.setProperty('--media-height', mediaHeight)
    }

    setHeightVariable()

    window.addEventListener('resize', setHeightVariable)
    return () => window.removeEventListener('resize', setHeightVariable)
  }, [])

  return (
    <ParentWrapper
      className={cn({ [className]: className })}
      {...(!isVideo && { href })}
      {...props}
    >
      <div
        className={styles['image']}
        ref={mediaRef}
      >
        {isVideo ? (
          <iframe
            title="YouTube video"
            src={`https://www.youtube.com/embed/${data.youtubeId}`}
          />
        ) : (
          <Image
            src={data.image.sourceUrl}
            alt=""
            fill
            loading="eager"
            priority
          />
        )}

        <div className={cn(styles['dots__wrapper--md'])}>{children}</div>
      </div>

      <div className={cn(styles['content'])}>
        <div className={cn(styles['dots__wrapper'])}>{children}</div>

        <div className={cn(styles['content__wrapper'])}>
          <p className={cn(styles['label'])}>
            <span>{data.label}</span>

            {data.connectedItem?.membersAcf?.membersOnly && (
              <Badge className={cn('badge--member')}>
                <StarIcon />
                Leden
              </Badge>
            )}
          </p>

          <p className={cn(styles['title'])}>{data.title}</p>

          <ReadMoreWrapper
            {...(isVideo && { href })}
            className={cn(styles['read-more'])}
          >
            Lees meer
          </ReadMoreWrapper>
        </div>
      </div>
    </ParentWrapper>
  )
}

function Controls({ selectPreviousTab, selectNextTab, className, ...props }) {
  return (
    <div
      className={cn({ [className]: className })}
      {...props}
    >
      <button
        type="button"
        aria-label="Vorige"
        name="Vorige"
        onClick={selectPreviousTab}
      >
        <ChevronLeftIcon />
      </button>

      <button
        type="button"
        name="Volgende"
        aria-label="Volgende"
        onClick={selectNextTab}
      >
        <ChevronRightIcon />
      </button>
    </div>
  )
}

export default IntroSlider
