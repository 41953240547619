import styles from './Badge.module.scss'
import cn from 'classnames'
import Link from 'next/link'

function Badge({ className, children, ...props }) {
  return (
    <WrapperComponent
      className={cn(styles['root'], { [className]: className })}
      {...props}
    >
      {children}
    </WrapperComponent>
  )
}

function WrapperComponent({ children, searchable, ...props }) {
  return searchable ? (
    <Link
      title="Zoeken"
      href={`/zoeken?query=${encodeURIComponent(children)}`}
    >
      <span {...props}>{children}</span>
    </Link>
  ) : (
    <span {...props}>{children}</span>
  )
}

export default Badge
